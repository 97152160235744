import { cva } from "class-variance-authority";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/@/components/ui/accordion";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogRemote,
  DialogTitle,
} from "~/@/components/ui/dialog";
import { ScrollArea } from "~/@/components/ui/scroll-area";
import { DefaultButton } from "../Buttons";
import { ArrowLeft } from "~/assets/icons/ArrowLeft";
import { CopyIcon } from "~/assets/icons/CopyIcon";
import { SuccessIcon } from "~/assets/icons/SuccessIcon";
import { FailedIcon } from "~/assets/icons/FailedIcon";
import { PendingIcon } from "~/assets/icons/PendingIcon";
import { TransactionKV } from "~/general/transaction";
import { useModalHandle } from "~/hooks/use-modal";
import { simpleCurrencyFormatter } from "~/libs/currency.helpers";
import { safeFormat } from "~/libs/date.helpers";
import {
  BankDetailsFactory,
  TrxFactory,
  TrxImpl,
  TrxLog,
  TxDestinationBusinessWallet,
  TxDestinationUserWallet,
} from "~/libs/factories/transaction";
import { StatusBadge } from "../Badges";
import { Divider } from "../Divider";
import { DialogModalHeader } from "../Modal";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const FALLBACK = TrxFactory({} as any);

export function WalletTransactionModal() {
  const modal = useModalHandle("transaction_details");

  const { data: transaction_data = FALLBACK } = modal;

  return (
    <DialogRemote id={"transaction_details"}>
      <DialogContent
        showClose={false}
        className="mx-auto w-11/12 max-w-[612px] gap-0 overflow-hidden p-0 font-body md:w-full"
      >
        <div className="flex w-full flex-col">
          <DialogHeader className="px-6 py-4">
            <div className="relative flex w-full items-center justify-between">
              <button type="button" onClick={() => modal.hide()}>
                <ArrowLeft w={"24"} h={"24"} color="#0094FF" />
              </button>
              <DialogTitle className="text-xl font-bold leading-8 text-[#0094FF]">
                Transaction
              </DialogTitle>
              <DialogDescription className="hidden" />
            </div>
          </DialogHeader>
          <Divider className="my-0 border-b-[1px] border-[#DEE5E7] opacity-50" />
        </div>

        <ScrollArea className={"max-h-[70vh] w-full px-8 pb-5 pt-[10px]"}>
          <TransactionDisplay
            type={transaction_data.status as "SUCCESS" | "FAILED" | "PENDING"}
            recipient="John Doe"
          >
            <Currency
              value={transaction_data.amount.value}
              currency={transaction_data.amount.currency}
            />
          </TransactionDisplay>

          <Divider />
          <Accordion
            type={"single"}
            collapsible
            defaultValue="transaction_details"
          >
            <AccordionItem value={"transaction_details"}>
              <AccordionTrigger>
                <span className="flex items-center justify-between font-normal text-zinc-700">
                  Transaction Details
                </span>
              </AccordionTrigger>
              <AccordionContent>
                <TransactionDetails {...transaction_data} />
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <SenderReceiverInfo type={"destination"} data={transaction_data} />
          <SenderReceiverInfo type={"source"} data={transaction_data} />

          <Accordion
            type={"single"}
            collapsible
            defaultValue="timestamp_details"
          >
            <AccordionItem value={"timestamp_details"}>
              <AccordionTrigger>
                <span className="flex items-center justify-between font-normal text-zinc-700">
                  Timestamp Details
                </span>
              </AccordionTrigger>
              <AccordionContent>
                <ListItem label={"Payment Expected"}>
                  <span className="text-base font-normal leading-6 text-[#303940]">
                    0-1 business days
                  </span>
                </ListItem>
                <ListItem label={"Payment Started"}>
                  <span className="flex items-center gap-2 text-base font-normal leading-6 text-[#303940]">
                    {safeFormat(transaction_data.created_at, "EEE d, MMM yyyy")}{" "}
                    <span className="text-sm">
                      {safeFormat(transaction_data.created_at, "h:mm:ssaa")}
                    </span>
                  </span>
                </ListItem>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </ScrollArea>

        <DialogFooter className="relative flex w-full flex-row items-center justify-center px-5 py-6 sm:justify-center">
          <DefaultButton
            label="Ok"
            onClick={() => modal.hide()}
            className="h-[38px] w-[126px] rounded-sm"
          />
        </DialogFooter>
      </DialogContent>
    </DialogRemote>
  );
}

function Currency({ currency, value }: { currency: string; value: number }) {
  if (currency === "--") return false;
  if (!currency) return value;

  const formatter = simpleCurrencyFormatter(currency);

  return formatter.format(value);
}

function ListItem(props: { label: string; children?: React.ReactNode }) {
  const { label, children } = props;

  return (
    <div className="my-[20px] flex items-center justify-between">
      <span className="font-body text-base font-normal leading-tight text-zinc-700">
        {label}
      </span>
      {children}
    </div>
  );
}

function TransactionDetails(transaction_data: TrxLog) {
  const tx_details: { label: string; value: React.ReactNode }[] = [
    {
      label: "Net Amount",
      value: (
        <span className="text-base font-bold leading-6 text-[#303940]">
          <Currency
            value={transaction_data.amount.value}
            currency={transaction_data.amount.currency}
          />
        </span>
      ),
    },
    {
      label: "Fee",
      value: (
        <span className="text-base font-normal leading-5 text-[#303940]">
          <Currency
            value={transaction_data.fees.value}
            currency={transaction_data.fees.currency}
          />
        </span>
      ),
    },
    {
      label: "Gross Amount",
      value: (
        <span className="text-base font-normal leading-6 text-[#303940]">
          <Currency
            value={transaction_data.total.value}
            currency={transaction_data.total.currency}
          />
        </span>
      ),
    },
    {
      label: "Type",
      value: (
        <span className="text-base font-bold leading-6 text-[#2E8F49]">
          {transaction_data.flow_type}
        </span>
      ),
    },
    {
      label: "Transaction ID",
      value: (
        <span className="text-base font-normal leading-6 text-[#303940]">
          {transaction_data.externalTransactionId || "--"}
        </span>
      ),
    },
  ];

  return tx_details.map((detail) => (
    <ListItem key={detail.label} label={detail.label}>
      {detail.value}
    </ListItem>
  ));
}

const variants = {
  SUCCESS: {
    icon: <SuccessIcon width="16" height="16" />,
    text: "Successful",
    bgColor: "bg-[#EDFDF0]",
    textColor: "text-[#3BB75E]",
  },
  FAILED: {
    icon: <FailedIcon width="16" height="16" />,
    text: "Failed",
    bgColor: "bg-[#FFEBEB]",
    textColor: "text-[#F05050]",
  },
  PENDING: {
    icon: <PendingIcon width="16" height="16" />,
    text: "Pending",
    bgColor: "bg-[#FFF9EB]",
    textColor: "text-[#FFAB00]",
  },
};

function TransactionDisplay({
  type = "SUCCESS",
  children,
  recipient,
}: {
  type: "SUCCESS" | "FAILED" | "PENDING";
  children: React.ReactNode;
  recipient: string;
}) {
  return (
    <div className="relative flex w-full flex-col items-center gap-2 px-6 py-4">
      <span
        className={`flex items-center justify-normal gap-2 rounded-2xl px-3 py-2 ${variants[type].bgColor}`}
      >
        {variants[type].icon}
        <span
          className={`text-center text-sm font-bold leading-none ${variants[type].textColor}`}
        >
          {variants[type].text}
        </span>
      </span>
      <span className="text-center font-body text-2xl font-bold leading-9 text-[#303940]">
        {children}
      </span>
      <span className="text-sm leading-5 text-[#303940]">
        <span className="font-normal">Payment to </span>
        <span className="font-bold">{recipient}</span>
      </span>
    </div>
  );
}

function SenderReceiverInfo({
  type,
  data,
}: {
  type: "source" | "destination";
  data?: TrxLog;
}) {
  const title =
    {
      source: "Sender Details",
      destination: "Recipient Details",
    }[type] ?? "--";

  console.log("transaction_data", data);

  if (!data) return null;

  const render = React.useCallback((record: TransactionKV) => {
    const hasCopy = typeof record?.copyText !== "undefined";
    return (
      <ListItem key={record.title} label={record.title}>
        <div className="flex items-center gap-2 text-base font-medium leading-normal text-[#002C3D]">
          <span>{record.value}</span>

          {hasCopy ? (
            <CopyToClipboard text={record.copyText}>
              <button type="button" className={""}>
                <CopyIcon />{" "}
              </button>
            </CopyToClipboard>
          ) : null}
        </div>
      </ListItem>
    );
  }, []);

  const content = () => {
    if (type === "destination") {
      // console.log("hasOwner", data);
      const hasOwner =
        // @ts-expect-error
        typeof data?.$original?.destination?.owner === "undefined";

      if (hasOwner)
        // @ts-expect-error
        return BankDetailsFactory(data?.$original?.destination)
          .toArray()
          .map(render);

      if (TrxImpl.isDestination(data, "USER"))
        // @ts-expect-error
        return TxDestinationUserWallet(data?.$original?.destination)
          .toArray()
          .map(render);

      if (TrxImpl.isDestination(data, "BUSINESS")) {
        // @ts-expect-error
        return TxDestinationBusinessWallet(data?.$original?.destination)
          .toArray()
          .map(render);
      }
    }

    if (type === "source") {
      const hasOwner = !data?.$original?.[type]?.owner;

      if (hasOwner)
        return BankDetailsFactory(data?.$original?.[type])
          .toArray()
          .map(render);

      const entityType = data?.$original?.[type]?.owner?.entityType;

      if (entityType === "USER")
        return TxDestinationUserWallet(data?.$original?.[type])
          .toArray()
          .map(render);

      if (entityType === "BUSINESS")
        return TxDestinationBusinessWallet(data?.$original?.[type])
          .toArray()
          .map(render);
    }

    return <p className={"text-muted-foreground"}>No data</p>;
  };

  return (
    <Accordion type={"single"} collapsible defaultValue={type}>
      <AccordionItem value={type}>
        <AccordionTrigger>
          <span className="flex items-center justify-between font-normal text-zinc-700">
            {title}
          </span>
        </AccordionTrigger>
        <AccordionContent>{content()}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
