export function PendingIcon({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_18318_2941"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_18318_2941)">
        <path
          d="M8.00016 11.3334C8.18905 11.3334 8.34739 11.2695 8.47516 11.1417C8.60294 11.0139 8.66683 10.8556 8.66683 10.6667C8.66683 10.4778 8.60294 10.3195 8.47516 10.1917C8.34739 10.0639 8.18905 10 8.00016 10C7.81127 10 7.65294 10.0639 7.52516 10.1917C7.39739 10.3195 7.3335 10.4778 7.3335 10.6667C7.3335 10.8556 7.39739 11.0139 7.52516 11.1417C7.65294 11.2695 7.81127 11.3334 8.00016 11.3334ZM8.00016 8.66671C8.18905 8.66671 8.34739 8.60282 8.47516 8.47504C8.60294 8.34726 8.66683 8.18893 8.66683 8.00004V5.33337C8.66683 5.14449 8.60294 4.98615 8.47516 4.85837C8.34739 4.7306 8.18905 4.66671 8.00016 4.66671C7.81127 4.66671 7.65294 4.7306 7.52516 4.85837C7.39739 4.98615 7.3335 5.14449 7.3335 5.33337V8.00004C7.3335 8.18893 7.39739 8.34726 7.52516 8.47504C7.65294 8.60282 7.81127 8.66671 8.00016 8.66671ZM8.00016 14.6667C7.07794 14.6667 6.21127 14.4917 5.40016 14.1417C4.58905 13.7917 3.8835 13.3167 3.2835 12.7167C2.6835 12.1167 2.2085 11.4112 1.8585 10.6C1.5085 9.78893 1.3335 8.92226 1.3335 8.00004C1.3335 7.07782 1.5085 6.21115 1.8585 5.40004C2.2085 4.58893 2.6835 3.88337 3.2835 3.28337C3.8835 2.68337 4.58905 2.20837 5.40016 1.85837C6.21127 1.50837 7.07794 1.33337 8.00016 1.33337C8.92239 1.33337 9.78905 1.50837 10.6002 1.85837C11.4113 2.20837 12.1168 2.68337 12.7168 3.28337C13.3168 3.88337 13.7918 4.58893 14.1418 5.40004C14.4918 6.21115 14.6668 7.07782 14.6668 8.00004C14.6668 8.92226 14.4918 9.78893 14.1418 10.6C13.7918 11.4112 13.3168 12.1167 12.7168 12.7167C12.1168 13.3167 11.4113 13.7917 10.6002 14.1417C9.78905 14.4917 8.92239 14.6667 8.00016 14.6667Z"
          fill="#FFAA22"
        />
      </g>
    </svg>
  );
}
