export function FailedIcon({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || "15"}
      height={height || "14"}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.49992" cy="7.00004" r="6.66667" fill="#F05050" />
      <path
        d="M9.66667 5L5.5 9.16667M5.5 5L9.66667 9.16667"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
