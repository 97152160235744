export function SuccessIcon({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
        fill="#2E8F49"
      />
      <path
        d="M6.46971 9.65228L4.81289 7.99546C4.62668 7.80925 4.32587 7.80925 4.13966 7.99546C3.95345 8.18168 3.95345 8.48248 4.13966 8.6687L6.13548 10.6645C6.3217 10.8507 6.6225 10.8507 6.80871 10.6645L11.8603 5.61289C12.0466 5.42668 12.0466 5.12587 11.8603 4.93966C11.6741 4.75345 11.3733 4.75345 11.1871 4.93966L6.46971 9.65228Z"
        fill="white"
      />
    </svg>
  );
}
